var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container" }, [
              _c(
                "div",
                { staticClass: "modal-header" },
                [
                  _vm._t("header", function () {
                    return [_c("h4", [_vm._v("Bewerk categorieën")])]
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "modal-header-close",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("close"),
                      ]),
                    ]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm.errors
                  ? _c("p", [
                      _c("b", [
                        _vm._v(
                          "Categorie kan niet worden aangemaakt omdat het naamveld niet is ingevuld."
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    attrs: { name: "addCategoryForm" },
                    on: { submit: _vm.submitForm },
                  },
                  [
                    _c("div", { staticClass: "row col-small-padding" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name",
                          },
                        ],
                        staticClass: "form-control col-md-7 col-xs-6",
                        attrs: {
                          name: "newCategoryName",
                          placeholder: "Nieuwe categorie",
                          type: "text",
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.name = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "col-md-1 col-xs-1 col-small-padding",
                      }),
                      _vm._v(" "),
                      !_vm.isEditing
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-daan-orange btn-with-icon col-md-4 col-xs-5",
                              on: { click: _vm.submitForm },
                            },
                            [
                              !_vm.justSaved
                                ? _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("add_circle"),
                                  ])
                                : _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons just-saved animated bounceIn",
                                    },
                                    [_vm._v("check_circle")]
                                  ),
                              _vm._v(
                                "\n                                    Toevoegen"
                              ),
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-daan-orange btn-with-icon col-4",
                              on: { click: _vm.submitForm },
                            },
                            [
                              !_vm.justSaved
                                ? _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("save"),
                                  ])
                                : _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons just-saved animated bounceIn",
                                    },
                                    [_vm._v("check_circle")]
                                  ),
                              _vm._v(
                                "\n                                    Opslaan"
                              ),
                            ]
                          ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row category-list" },
                  _vm._l(_vm.categoryData, function (category) {
                    return _c("div", { staticClass: "category-list-item" }, [
                      _c("div", { staticClass: "category-list-name" }, [
                        _vm._v(_vm._s(category.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "category-list-actions" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(category.id)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("edit"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(category.id)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("delete"),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer", function () {
                    return [
                      _c("div", { staticClass: "col-12 text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-daan-orange btn-with-icon",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("close")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("close"),
                            ]),
                            _vm._v(
                              " Sluiten\n                                "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }