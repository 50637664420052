<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header">
                            <slot name="header">
                                <h4>Bewerk categorieën</h4>
                            </slot>
                            <div @click="$emit('close')" class="modal-header-close">
                                <i class="material-icons">close</i>
                            </div>
                        </div>

                        <div class="modal-body">
                            <p v-if="errors">
                                <b>Categorie kan niet worden aangemaakt omdat het naamveld niet is ingevuld.</b>
                            </p>
                            <form @submit="submitForm" name="addCategoryForm">
                                <div class="row col-small-padding">
                                    <input
                                        class="form-control col-md-7 col-xs-6"
                                        name="newCategoryName"
                                        placeholder="Nieuwe categorie"
                                        type="text"
                                        v-model="name"
                                    />
                                    <div class="col-md-1 col-xs-1 col-small-padding"></div>
                                    <a @click="submitForm" class="btn btn-daan-orange btn-with-icon col-md-4 col-xs-5 " v-if="!isEditing">
                                        <i class="material-icons" v-if="!justSaved">add_circle</i>
                                        <i class="material-icons just-saved animated bounceIn" v-else>check_circle</i>
                                        Toevoegen</a>
                                    <a @click="submitForm" class="btn btn-daan-orange btn-with-icon col-4" v-else>
                                        <i class="material-icons" v-if="!justSaved">save</i>
                                        <i class="material-icons just-saved animated bounceIn" v-else>check_circle</i>
                                        Opslaan</a>
                                </div>
                            </form>
                            <hr/>
                            <div class="row category-list">
                                <div class="category-list-item" v-for="category in categoryData">
                                    <div class="category-list-name">{{category.name}}</div>
                                    <div class="category-list-actions">
                                        <a @click="editItem(category.id)" href="#"><i class="material-icons">edit</i></a>
                                        <a @click="deleteItem(category.id)" href="#"><i class="material-icons">delete</i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <slot name="footer">
                                <div class="col-12 text-center">
                                    <a @click="$emit('close')" class="btn btn-daan-orange btn-with-icon" href="#">
                                        <i class="material-icons">close</i> Sluiten
                                    </a>
                                </div>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "app",
        mounted() {
            this.loadCategories();
        },
        data() {
            return {
                categoryData: "",
                errors: false,
                name: "",
                id: "",
                isEditing: false,
                justSaved: false,
            }
        },
        methods: {
            loadCategories() {
                axios.get("/categories").then(resp => {
                    if (resp.data) {
                        this.categoryData = resp.data.data;
                    }
                });
            },
            deleteItem(id) {
                var yes = confirm("Weet je het zeker?");
                if (yes) {
                    if (id) {
                        //single item
                        axios.delete("/categories/" + id).then((resp) => {
                            if (resp.data == 'success') {
                                this.loadCategories();
                                this.$emit('reloadcategories');
                            }
                        });
                    }
                } else {
                    //nothing done
                }
            },
            editItem(id) {
                axios.get("/categories/" + id).then(resp => {
                    if (resp.data) {
                        this.id = resp.data.id;
                        this.name = resp.data.name;
                        this.isEditing = true;
                    }
                });
            },
            submitForm(e) {
                e.preventDefault();
                if (!this.name) {
                    this.errors = true;
                }
                if (this.isEditing) {
                    this.saveItem();
                } else {
                    this.submitNew();
                }
            },
            submitNew() {
                axios
                    .post('/categories', {name: this.name})
                    .then(resp => {
                        if (resp.data == "success") {
                            this.name = "";

                            this.loadCategories();
                            this.$emit('reloadcategories');
                            this.savedSuccess();
                        }
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            },
            saveItem() {
                axios
                    .put('categories/' + this.id, {name: this.name})
                    .then(resp => {
                        if (resp.data == "success") {
                            this.name = "";

                            this.loadCategories();
                            this.$emit('reloadcategories');
                            this.savedSuccess();
                        }
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            },
            savedSuccess() {
                this.justSaved = true;
                setTimeout(() => {
                    this.justSaved = false
                }, 2000);
            }
        }
    };
</script>
