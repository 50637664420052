var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c(
            "div",
            {
              staticClass: "modal-wrapper",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-container",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-header" },
                    [
                      _vm._t("header", function () {
                        return [
                          _c("h4", [
                            _vm._v(
                              "Weet je zeker dat je " +
                                _vm._s(_vm.numberOfFiles) +
                                " bestand"
                            ),
                            _vm.numberOfFiles > 1
                              ? _c("span", [_vm._v("en")])
                              : _vm._e(),
                            _vm._v(" wilt verwijderen?"),
                          ]),
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "modal-header-close",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("close"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body text-center" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-daan-red btn-with-icon",
                        on: { click: _vm.deleteItem },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("delete"),
                        ]),
                        _vm._v(" Verwijderen\n                        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "red-link",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Annuleren\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }