import Axios from "axios";
export default {
    check() {
        try {

            Axios.get('/me').then(resp => {
                if (resp.data) {
                    var Ls = window.localStorage;
                    Ls.setItem('role', resp.data.roles[0].name);
                }
            });

        } catch (e) { }
    },

    getRole() {
        var Ls = window.localStorage;
        return Ls.getItem('role');
    }
}