var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "modal" }, on: { keyup: _vm.nextItem } },
        [
          _c("div", { staticClass: "modal-mask" }, [
            _c(
              "div",
              {
                staticClass: "modal-wrapper",
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-container",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-header" },
                      [
                        _vm._t("header", function () {
                          return [
                            _c("h4", [
                              _vm._v(
                                "Details van " + _vm._s(_vm.mediaItemData.name)
                              ),
                            ]),
                          ]
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal-header-close",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("close")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("close"),
                            ]),
                          ]
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticClass: "text-center" }, [
                        _vm.isVideo
                          ? _c("img", {
                              staticClass:
                                "media-modal-image-preview video-placeholder",
                              attrs: { src: "images/video-placeholder.png" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.mediaItemData.url &&
                        !_vm.mediaItemData.embed_url &&
                        !_vm.mediaItemData.mime_type.includes("video")
                          ? _c("img", {
                              staticClass: "media-modal-image-preview",
                              attrs: {
                                src: _vm.mediaItemData.url,
                                id: "media-modal-image",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isYoutubeVideo
                          ? _c("iframe", {
                              attrs: {
                                src: _vm.mediaItemData.embed_url,
                                allow:
                                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                                allowfullscreen: "",
                                frameborder: "0",
                                height: "315",
                                width: "100%",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "clearfix" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "prev-button" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.nextItem("l")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("keyboard_arrow_left"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "next-button" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.nextItem("r")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("keyboard_arrow_right"),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("table", { staticClass: "table table-striped" }, [
                        _c("tbody", [
                          _vm.isYoutubeVideo
                            ? _c("tr", [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _vm._v("Bekijk op YouTube"),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.mediaItemData.youtube_url,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Klik hier")]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", { attrs: { scope: "row" } }, [
                              _vm._v("Naam"),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.mediaItemData.name) +
                                  "\n                                    "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "pull-right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditFilename()
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("edit"),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.editName
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "2" } }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newName,
                                        expression: "newName",
                                      },
                                    ],
                                    ref: "nameEditField",
                                    staticClass: "form-control col-12",
                                    attrs: {
                                      name: "file_name_edit",
                                      placeholder: "Typ hier de nieuwe naam",
                                      type: "text",
                                    },
                                    domProps: { value: _vm.newName },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.saveFileName()
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.newName = $event.target.value
                                      },
                                    },
                                  }),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "text-center" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-daan-orange btn-with-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveFileName()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("save")]
                                        ),
                                        _vm._v(
                                          " Opslaan\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "red-link col-6",
                                        on: {
                                          click: function ($event) {
                                            _vm.editName = false
                                          },
                                        },
                                      },
                                      [_vm._v("Annuleren")]
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.categories.length
                            ? _c("tr", [
                                _c("th", { attrs: { scope: "row" } }, [
                                  _vm._v("Categorie"),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.mediaItemData.categories,
                                      function (category) {
                                        return _vm.mediaItemData.categories
                                          ? _c("li", [
                                              _vm._v(
                                                _vm._s(category.name) +
                                                  "\n                                        "
                                              ),
                                            ])
                                          : _vm._e()
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn-daan-gray btn-small btn-with-icon-left",
                                      on: {
                                        click: function ($event) {
                                          return _vm.mediaToCategory()
                                        },
                                      },
                                    },
                                    [
                                      _c("small", [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("link")]
                                        ),
                                      ]),
                                      _vm._v(" Categorieën"),
                                    ]
                                  ),
                                ]),
                              ])
                            : _c("tr", { staticClass: "text-center" }, [
                                _c("td", { attrs: { colspan: "2" } }, [
                                  _c("em", [
                                    _vm._v("Niet aan een categorie gekoppeld"),
                                  ]),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "mediaToCategory",
                                            _vm.mediaItemId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Koppelen / Ontkoppelen")]
                                  ),
                                ]),
                              ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-daan-orange btn-with-icon",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("download", _vm.mediaItemId)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("cloud_download"),
                            ]),
                            _vm._v(" Download\n                            "),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.Auth.getRole() === "admin"
                          ? _c(
                              "a",
                              {
                                staticClass: "red-link",
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("delete", _vm.mediaItemId)
                                  },
                                },
                              },
                              [_vm._v("Verwijder bestand")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }