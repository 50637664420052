<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div @click="$emit('close')" class="modal-wrapper">
                    <div @click.stop class="modal-container">

                        <div class="modal-header">
                            <slot name="header">
                                <h4>Weet je zeker dat je {{ numberOfFiles }} bestand<span v-if="numberOfFiles > 1">en</span> wilt verwijderen?</h4>
                            </slot>
                            <div @click="$emit('close')" class="modal-header-close"><i class="material-icons">close</i></div>
                        </div>

                        <div class="modal-body text-center">
                            <a @click="deleteItem" class="btn btn-daan-red btn-with-icon">
                                <i class="material-icons">delete</i> Verwijderen
                            </a>
                            <a @click="$emit('close')" class="red-link">
                                Annuleren
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "app",
        props: ['items'],
        data() {
            return {
                numberOfFiles: 1
            }
        },
        mounted() {
            this.numberOfFiles = this.items.length;
        },
        methods: {
            deleteItem(id) {
                axios.post('/media/delete', {items: this.items}).then((resp) => {
                    if (resp.data == 'success') {
                        this.$emit('deleted');
                    }
                    this.$emit('close');
                }).catch((error) => {
                    console.log(error);
                });
            },
        }
    };
</script>
