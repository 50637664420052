<template>
    <div>
        <transition @keyup='nextItem' name="modal">
            <div class="modal-mask">
                <div @click="$emit('close')" class="modal-wrapper">
                    <div @click.stop class="modal-container">
                        <div class="modal-header">
                            <slot name="header">
                                <h4>Details van {{ mediaItemData.name }}</h4>
                            </slot>
                            <div @click="$emit('close')" class="modal-header-close">
                                <i class="material-icons">close</i>
                            </div>
                        </div>

                        <div class="modal-body">
                            <div class="text-center">
                                <img class="media-modal-image-preview video-placeholder" src="images/video-placeholder.png" v-if="isVideo"/>
                                <img
                                    :src="mediaItemData.url"
                                    class="media-modal-image-preview"
                                    id="media-modal-image"
                                    v-if="mediaItemData.url && !mediaItemData.embed_url && !mediaItemData.mime_type.includes('video')"
                                />

                                <iframe
                                    :src="mediaItemData.embed_url"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                    frameborder="0"
                                    height="315"
                                    v-if="isYoutubeVideo"
                                    width="100%"
                                ></iframe>
                                <div class="clearfix"></div>
                                <div class="prev-button">
                                    <a @click="nextItem('l')"><i class="material-icons">keyboard_arrow_left</i></a>
                                </div>
                                <div class="next-button">
                                    <a @click="nextItem('r')"><i class="material-icons">keyboard_arrow_right</i></a>
                                </div>
                                <!--                <div class="social-share-icons">-->
                                <!--                  <a-->
                                <!--                    target="_blank"-->
                                <!--                    :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + mediaItemData.share_url"-->
                                <!--                  >-->
                                <!--                    <img src="images/linkedin.png" />-->
                                <!--                  </a>-->
                                <!--                  <a-->
                                <!--                    target="_blank"-->
                                <!--                    :href="'http://www.facebook.com/sharer.php?u=' + mediaItemData.share_url"-->
                                <!--                  >-->
                                <!--                    <img src="images/facebook.png" />-->
                                <!--                  </a>-->
                                <!--                  <a-->
                                <!--                    target="_blank"-->
                                <!--                    :href="'http://twitter.com/share?text='+ mediaItemData.name +'&url='+ mediaItemData.share_url"-->
                                <!--                  >-->
                                <!--                    <img src="images/twitter.png" />-->
                                <!--                  </a>-->
                                <!--                </div>-->
                            </div>
                            <table class="table table-striped">
                                <tbody>
                                <tr v-if="isYoutubeVideo">
                                    <th scope="row">Bekijk op YouTube</th>
                                    <td>
                                        <a :href="mediaItemData.youtube_url" target="_blank">Klik hier</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Naam</th>
                                    <td>
                                        {{ mediaItemData.name }}
                                        <a @click="showEditFilename()" class=" pull-right"><i
                                            class="material-icons"
                                        >edit</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="editName">
                                    <td colspan="2">
                                        <input
                                            class="form-control col-12"
                                            name="file_name_edit"
                                            placeholder="Typ hier de nieuwe naam"
                                            ref="nameEditField"
                                            type="text"
                                            v-model="newName"
                                            v-on:keyup.enter="saveFileName()"
                                        /><br/>
                                        <div class="text-center">
                                            <a @click="saveFileName()" class="btn btn-daan-orange btn-with-icon">
                                                <i class="material-icons">save</i> Opslaan
                                            </a>
                                            <a
                                                @click="editName = false"
                                                class="red-link col-6"
                                            >Annuleren</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="categories.length">
                                    <th scope="row">Categorie</th>
                                    <td>
                                        <ul>
                                            <li
                                                v-for="category in mediaItemData.categories"
                                                v-if="mediaItemData.categories"
                                            >{{ category.name }}
                                            </li>
                                        </ul>
                                        <a @click="mediaToCategory()" class="btn-daan-gray btn-small btn-with-icon-left"><small><i class="material-icons">link</i></small> Categorieën</a>
                                    </td>
                                </tr>
                                <tr class="text-center" v-else>
                                    <td colspan="2">
                                        <em>Niet aan een categorie gekoppeld</em><br/>
                                        <a @click="$emit('mediaToCategory', mediaItemId)">Koppelen / Ontkoppelen</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="text-center">
                                <a
                                    @click="$emit('download', mediaItemId)"
                                    class="btn btn-daan-orange btn-with-icon"
                                >
                                    <i class="material-icons">cloud_download</i> Download
                                </a>
                                <a
                                    @click="$emit('delete', mediaItemId)"
                                    class="red-link"
                                    v-if="Auth.getRole() === 'admin'"
                                >Verwijder bestand</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import mixins from '../mixins.js';

    export default {
        name: 'app',
        props: ['mediaItemId'],
        mixins: [mixins],
        mounted() {
            this.localMediaItemId = this.mediaItemId;
            this.getMediaItem();
            document.addEventListener("keyup", this.nextItem);
        },
        data() {
            return {
                Auth: Auth,
                localMediaItemId: 0,
                mediaItemData: {},
                mediaWidth: 0,
                mediaHeight: 0,
                isYoutubeVideo: false,
                isVideo: false,
                categories: [],
                videoUrl: '',
                editName: false,
                newName: ''
            };
        },
        methods: {
            //todo: work with array index of items and get the id's from there (because sometimes items are deleted and we get a 404)
            nextItem(direction = 'r') {
                if ((event.keyCode === 39 || direction === 'r') && this.localMediaItemId > 1) {
                    this.localMediaItemId = this.mediaItemData.previous_id;
                    this.getMediaItem();
                } else if (event.keyCode === 37 || direction === 'l') {
                    this.localMediaItemId = this.mediaItemData.next_id;
                    this.getMediaItem();
                }
            },
            getMediaItem() {
                if (this.mediaItemId) {
                    axios
                        .get('media/' + this.localMediaItemId + '/view')
                        .then(resp => {
                            if (resp.data) {
                                this.mediaItemData = resp.data;
                                if (this.mediaItemData.url && !this.mediaItemData.youtube_url) {
                                    setTimeout(() => {
                                        this.mediaWidth = document.getElementById(
                                            'media-modal-image'
                                        ).width;
                                        this.mediaHeight = document.getElementById(
                                            'media-modal-image'
                                        ).height;
                                    }, 1000);
                                }
                                this.isYoutubeVideo = !!this.mediaItemData.youtube_url;
                                this.isVideo = this.mediaItemData.mime_type.includes('video');
                                this.categories = this.mediaItemData.categories;
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            },
            saveFileName() {
                axios
                    .put('media/' + this.mediaItemId + '/name', {
                        new_name: this.newName
                    })
                    .then(resp => {
                        if (resp.data) {
                            this.getMediaItem();
                            this.editName = false;
                            this.$emit('update');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            mediaToCategory() {
                this.$emit('close');
                this.$emit('mediaToCategory', this.mediaItemId);

            },
            showEditFilename() {
                this.editName = !this.editName;
                this.$nextTick(function () {
                    this.$refs.nameEditField.focus();
                })
            }
        },
        filters: {
            formatBytes(a, b) {
                if (0 == a) return '0 Bytes';
                var c = 1024,
                    d = b || 2,
                    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    f = Math.floor(Math.log(a) / Math.log(c));
                return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
            }
        }
    };
</script>
