<template>
    <section class="gallery-block">
        <div class="container">
            <div class="row filter-row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-xs-6 col-md-3 col-small-padding">
                            <input
                                @input="filter"
                                class="form-control"
                                placeholder="Waar wil je op zoeken?"
                                type="text"
                                v-model="filterData.search"
                            />
                        </div>
                        <div class="col-xs-6 col-md-3 col-small-padding">
                            <select
                                @change="filter"
                                class="form-control"
                                label="Categorie"
                                name="category"
                                v-model="filterData.category"
                            >
                                <option value>Alle categorieen</option>
                                <option :value="category.id" v-for="category in categories">{{ category.name }}</option>
                            </select>
                        </div>
                        <div class="col-xs-6 col-md-3 col-small-padding">
                            <select
                                @change="filter"
                                class="form-control"
                                label="Type"
                                name="type"
                                v-model="filterData.type"
                            >
                                <option value>Alle types</option>
                                <option value="images">Afbeeldingen</option>
                                <option value="msdoc">Documenten</option>
                                <option value="msxls">Spreadsheets</option>
                                <option value="msppt">Presentaties</option>
                                <option value="pdf">PDF</option>
                                <option value="youtube">YouTube video's</option>
                            </select>
                        </div>
                        <div class="col-md-3 col-xs-12 text-center col-small-padding gallery-action-buttons">
                            <a @click="doAction('delete')" class="col-1" v-if="Auth.getRole() === 'admin' && checkedItems !== 0"><i class="material-icons orange-icon">delete</i></a>
                            <a @click="doAction('download')" class="col-1" v-if="checkedItems !== 0"><i class="material-icons orange-icon">vertical_align_bottom</i></a>
                            <div class="pull-right">
                                <a
                                    @click="showModal = true"
                                    data-placement="top"
                                    data-toggle="tooltip"
                                    title="Bestanden uploaden"
                                    v-if="Auth.getRole() === 'admin'"
                                >
                                    <i class="material-icons orange-icon">vertical_align_top</i>
                                </a>
                                <a
                                    :class="{'orange-icon': allChecked, 'disabled-link': !allChecked}"
                                    @click="checkAll"
                                    data-placement="top"
                                    data-toggle="tooltip"
                                    title="Selecteer alle items"
                                >
                                    <i class="material-icons">check_box</i>
                                </a>
                                <a
                                    @click="showCategoryModal = true"
                                    data-placement="top"
                                    data-toggle="tooltip"
                                    title="Categorieën beheren"
                                    v-if="Auth.getRole() === 'admin'"
                                >
                                    <i class="material-icons orange-icon">filter_none</i>
                                </a>
                                <a
                                    data-placement="top"
                                    data-toggle="tooltip"
                                    href="/users"
                                    title="Gebruikers beheren"
                                    v-if="Auth.getRole() === 'admin'"
                                >
                                    <i class="material-icons orange-icon">settings</i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-6 col-xs-6" v-for="item in mediaData">
                    <div
                        :style="backgroundStyle(item)"
                        @click="downloadItem(item.id)"
                        class="gallery-block__item lazy"
                    >
                        <div
                            :class="{'checked' : checkChecked(item.id)}"
                            @click.stop="checkItem(item.id)"
                            class="gallery-block-item__check block-button"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Selecteer voor bulk-actie"
                        >
                            <i class="material-icons">check</i>
                        </div>
                        <div
                            @click.stop="deleteItem(item.id)"
                            class="gallery-block-item__delete block-button"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Verwijder dit item"
                            v-if="Auth.getRole() == 'admin'"
                        >
                            <i class="material-icons">delete</i>
                        </div>
                    </div>
                    <div class="file-name">{{ item.name }}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-4"></div>
                <div class="d-flex justify-content-center">
                    <div
                        :class="{'disabled': currentItems === totalMediaFiles}"
                        @click="loadMore"
                        class="btn loadMoreButton btn-daan-gray"
                        data-placement="top"
                        data-toggle="tooltip"
                        title="Laad volgende 12 items"
                    >Meer laden ({{currentItems}} van {{totalMediaFiles}} geladen)
                    </div>
                </div>
            </div>
        </div>
        <upload-modal @close="showModal = false" v-if="showModal" v-on:uploaded="filter()"></upload-modal>
        <delete-modal
            :items="deleteItems"
            @close="showDeleteModal = false"
            v-if="showDeleteModal"
            v-on:deleted="deleted"
        ></delete-modal>
        <category-modal
            @close="showCategoryModal = false"
            v-if="showCategoryModal"
            v-on:reloadcategories="getCategories"
        ></category-modal>
        <media-modal
            :mediaItemId="selectedMediaItemId"
            @close="showMediaModal = false"
            v-if="showMediaModal"
            v-on:delete="deleteItem"
            v-on:download="downloadItem"
            v-on:mediaToCategory="mediaToCategory"
            v-on:update="filter"
        ></media-modal>
        <media-to-category-modal
            :mediaItemId="selectedMediaItemId"
            v-if="showMediaToCategoryModal"
            v-on:cancel="showMediaToCategoryModal = false"
        ></media-to-category-modal>
    </section>
</template>
<script>
    import uploadForm from './UploadForm';
    import deleteModal from './DeleteModal';
    import categoryModal from './CategoryModal';
    import mediaModal from './MediaModal';
    import mediaToCategoryModal from './MediaToCategoryModal';

    export default {
        components: {
            uploadModal: uploadForm,
            deleteModal: deleteModal,
            categoryModal: categoryModal,
            mediaModal: mediaModal,
            mediaToCategoryModal: mediaToCategoryModal
        },
        data() {
            return {
                'Auth': Auth,
                showModal: false,
                showDeleteModal: false,
                showCategoryModal: false,
                showMediaModal: false,
                showMediaToCategoryModal: false,
                mediaData: [],
                checkedItems: [],
                deleteItems: [],
                allChecked: false,
                totalMediaFiles: 0,
                currentItems: 0,
                getItems: 12,
                selectedMediaItemId: '',
                categories: [],
                filterData: {
                    search: '',
                    category: '',
                    type: ''
                },
                action: ''
            };
        },
        mounted() {
            this.getMedia(32);
            this.getCategories();
        },
        methods: {
            deleted() {
                this.checkedItems = [];
                this.showDeleteModal = !this.showDeleteModal;
                this.filter();
            },
            getCategories() {
                axios
                    .get('/categories')
                    .then(resp => {
                        if (resp.data) {
                            this.categories = resp.data.data;
                        }
                    })
                    .catch(error => {
                    });
            },
            // filter with param deconstruction for usage in axios
            filter() {
                var params = new URLSearchParams();
                _.each(this.filterData, (value, item) => {
                    params.append(item, value);
                });
                var request = {
                    params: params
                };
                this.request = request;
                this.getMedia(this.getItems, request);
            },
            // get media from server (number of items and request param from filter)
            getMedia(number = 12, request = null) {
                if (this.request) {
                    let request = this.request
                }
                number = number !== null ? number : this.getItems;
                axios
                    .get('/media/data?number=' + number, request)
                    .then(response => {
                        this.mediaData = [];
                        if (response.data.total) {
                            this.totalMediaFiles = response.data.total;
                        }
                        if (response.data.media) {
                            this.mediaData = response.data.media;
                            this.currentItems = response.data.media.length;
                        } else if (response.data.total === 0) {
                            this.currentItems = 0;
                            this.totalMediaFiles = 0;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            downloadItem(id) {
                // nothing selected, nothing to do
                if ((this.checkedItems.length !== 'undefined') && (this.checkedItems.length < 1 && !id)) {
                    return;
                }
                if (id) {
                    //single item
                    if (this.showMediaModal) {
                        window.open('media/download?files=' + id);
                    } else {
                        this.showMediaModal = true;
                        this.selectedMediaItemId = id;
                    }
                } else {
                    //multiple items
                    var downloadItems = this.checkedItems;
                    window.open('media/download?files=' + downloadItems);
                }
            },
            mediaToCategory(mediaId) {
                this.showMediaModal = false;
                this.showMediaToCategoryModal = true;
            },
            openYoutube(link) {
                window.open(link);
            },
            deleteItem(id) {
                if (this.checkedItems.length < 1 && !id) {

                } else {
                    this.showMediaModal = false;
                    if (id) {
                        //single item - we expect an array
                        this.deleteItems = [id];
                        this.showDeleteModal = true;
                    } else {
                        //multiple items
                        this.deleteItems = this.checkedItems;
                        this.showDeleteModal = true;
                    }
                }
            },
            checkItem(id) {
                if (_.contains(this.checkedItems, id)) {
                    this.checkedItems = _.reject(this.checkedItems, function (item, key) {
                        return item === id;
                    });
                } else {
                    this.checkedItems.push(id);
                }
            },
            checkChecked(id) {
                return this.checkedItems.includes(id);
            },
            checkAll() {
                if (this.allChecked) {
                    this.checkedItems = [];
                    this.allChecked = false;
                } else {
                    _.each(this.mediaData, item => {
                        this.checkedItems.push(item.id);
                        this.allChecked = true;
                    });
                }
            },
            loadMore() {
                let loadItems = this.getItems + 12;
                if (this.request) {
                    this.getMedia(loadItems, this.request);
                } else {
                    this.getMedia(loadItems);
                }
                this.getItems = loadItems;
            },
            doAction(event) {
                if (this.checkedItems.length < 1) {
                    return;
                }
                this.action = '';
                switch (event) {
                    case 'delete':
                        this.deleteItem();
                        break;
                    case 'download':
                        this.downloadItem();
                        break;
                }
            },
            backgroundStyle: function (item) {
                const mime = item.mime;
                if (mime.includes('video')) {
                    return "background-image: url('images/video-placeholder.png'); background-size: 175px; background-repeat: no-repeat;";
                } else if (['word', 'rtf'].some(type => mime.search(type) > 0) || ['odt', 'doc', 'docx'].includes(item.extension)) {
                    return "background-image: url('images/word-placeholder.png'); background-size: 175px; background-repeat: no-repeat;";
                } else if (['excel', 'spreadsheet'].some(type => mime.search(type) > 0) || ['ods', 'xls', 'xlsx'].includes(item.extension)) {
                    return "background-image: url('images/excel-placeholder.png'); background-size: 175px; background-repeat: no-repeat;";
                } else if (['powerpoint', 'presentation'].some(type => mime.search(type) > 0) || ['odp', 'ppt', 'pptx'].includes(item.extension)) {
                    return "background-image: url('images/powerpoint-placeholder.png'); background-size: 175px; background-repeat: no-repeat;";
                } else {
                    return "background-image: url('" + item.url + "'); background-size: 175px; background-repeat: no-repeat;";
                }
            }
        }
    };
</script>
