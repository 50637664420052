<template>
    <div>
        <transition name="mediaToCategoryModal">
            <div class="modal-mask">
                <div @click="$emit('cancel')" class="modal-wrapper">
                    <div @click.stop class="modal-container">
                        <div class="modal-header">
                            <slot name="header">
                                <h4>{{ mediaItemData.name }} aan categorie koppelen</h4>
                            </slot>
                            <div @click="$emit('cancel')" class="modal-header-close">
                                <i class="material-icons">close</i>
                            </div>
                        </div>

                        <div class="modal-body">
                            <div class="row" v-if="selectedCategories.length">
                                <div class="col-12">
                                    <em>Gekozen categorieen</em>
                                </div>
                                <div
                                    class="col-12 category-list-item"
                                    v-for="(category, key) in selectedCategories"
                                >
                                    <div class="category-list-name">{{category.name}}</div>
                                    <div class="category-list-actions">
                                        <i @click="deleteCategory(category.id)" class="material-icons">delete</i>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <select
                                @change="categoryChange($event)"
                                class="form-control"
                                name="category"
                                placeholder="Kies een categorie"
                                v-model="category_id"
                            >
                                <option value>Kies een categorie</option>
                                <option
                                    v-bind:key="category.name"
                                    v-bind:value="JSON.stringify(category)"
                                    v-for="category in categories"
                                >{{ category.name }}
                                </option>
                            </select>
                            <div class="text-center"><br/>
                                <span v-if="!savingSuccess">
                  <a @click="attachCategories()" class="btn btn-daan-orange btn-with-icon">
                    <i class="material-icons">cloud_download</i> Koppelen
                  </a>
                </span>
                                <span v-else>
                  <a class="btn btn-daan-orange btn-with-icon">
                    <i class="material-icons">check_circle</i>Gelukt!
                  </a>
                </span>
                                <a @click="$emit('cancel')" class="red-link">Annuleren</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import mixins from '../mixins.js';

    export default {
        name: 'app',
        props: ['mediaItemId'],
        mixins: [mixins],
        mounted() {
            this.getMediaItem();
            axios
                .get('/categories')
                .then(resp => {
                    if (resp.data) {
                        this.categories = resp.data.data;
                    }
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        },
        data() {
            return {
                Auth: Auth,
                mediaItemData: {},
                categories: '',
                selectedCategories: [],
                category_id: '',
                savingSuccess: false
            };
        },
        methods: {
            // Attach the categories to a media item
            attachCategories() {
                axios
                    .post('media/' + this.mediaItemId + '/categories', {
                        categories: this.selectedCategories
                    })
                    .then(resp => {
                        if (resp.data == 'success') {
                            this.savingSuccess = true;
                            setTimeout(() => {
                                this.$emit('cancel');
                                this.savingSuccess = false;
                            }, 1500);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            // on category change, add it to the stack
            categoryChange(event) {
                let item = JSON.parse(event.target.value);
                this.selectedCategories.push(item);
            },
            // remove category from the selection
            deleteCategory(id) {
                this.selectedCategories = _.reject(this.selectedCategories, item => {
                    console.log(item.id);
                    return item.id == id;
                });
            },
            getMediaItem() {
                if (this.mediaItemId) {
                    axios
                        .get('media/' + this.mediaItemId + '/view')
                        .then(resp => {
                            if (resp.data) {
                                this.mediaItemData = resp.data;
                                this.selectedCategories = resp.data.categories;
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            }
        }
    };
</script>
