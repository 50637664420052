<template>
    <section class="user-block">
        <div class="container">
            <div class="row filter-row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-small-padding">
                            <div class="text-center">
                                <h4>Gebruikersbeheer</h4>
                                <br/>
                            </div>
                            <table class="table user-table">
                                <thead>
                                <td>Gebruikersnaam</td>
                                <td>E-mailadres (voor inloggen)</td>
                                <td>Rol</td>
                                <td>Acties</td>
                                </thead>
                                <tbody>
                                <tr v-for="user in users">
                                    <td>{{ user.name }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>
                                        <ul>
                                            <li v-for="role in user.roles">
                                                {{ role.name }}
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <a @click="editUser(user.id)">
                                            <i class="material-icons">edit</i>
                                        </a>
                                        <a @click="deleteUser(user.id)">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-5"></div>
                        <div class="d-flex justify-content-center">
                            <a
                                @click="createUser()"
                                class="btn btn-daan-orange btn-with-icon"
                                data-placement="top"
                                data-toggle="tooltip"
                                title="Nieuwe gebruiker"
                            ><i class="material-icons">add_circle</i> gebruiker</a><br/>
                        </div>
                    </div>
                    <div class="row"><br/>

                    </div>
                </div>
            </div>
        </div>
        <user-modal
            :id="selectedUserId"
            @close="showUserModal = false"
            v-if="showUserModal"
            v-on:reload="loadUsers()"
        ></user-modal>
    </section>
</template>

<script>
    import UserModal from './UserModal';

    export default {
        components: {
            UserModal: UserModal
        },
        data() {
            return {
                users: {},
                userData: {},
                showUserModal: false,
                selectedUserId: false
            };
        },
        mounted() {
            this.loadUsers();
        },
        methods: {
            loadUsers() {
                axios.get('/users').then(resp => {
                    if (resp.data) {
                        this.users = resp.data;
                    }
                });
            },
            editUser(id) {
                this.selectedUserId = id;
                this.showUserModal = true;
            },
            createUser() {
                this.selectedUserId = false;
                this.showUserModal = true;
            },
            deleteUser(id) {
                let confirmed = confirm('Zeker weten?');
                if (confirmed) {
                    axios
                        .delete('/users/' + id)
                        .then(resp => {
                            if(resp.status == '200') {
                                this.loadUsers();
                            }
                        })
                        .catch(error => {
                            alert('Er ging iets mis, probeer het later nog eens');
                        });
                }
            }
        }
    };
</script>
