var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "mediaToCategoryModal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c(
            "div",
            {
              staticClass: "modal-wrapper",
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-container",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-header" },
                    [
                      _vm._t("header", function () {
                        return [
                          _c("h4", [
                            _vm._v(
                              _vm._s(_vm.mediaItemData.name) +
                                " aan categorie koppelen"
                            ),
                          ]),
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "modal-header-close",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("close"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _vm.selectedCategories.length
                      ? _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-12" }, [
                              _c("em", [_vm._v("Gekozen categorieen")]),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.selectedCategories,
                              function (category, key) {
                                return _c(
                                  "div",
                                  { staticClass: "col-12 category-list-item" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "category-list-name" },
                                      [_vm._v(_vm._s(category.name))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "category-list-actions" },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass: "material-icons",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteCategory(
                                                  category.id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("delete")]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.category_id,
                            expression: "category_id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          name: "category",
                          placeholder: "Kies een categorie",
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.category_id = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.categoryChange($event)
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("Kies een categorie"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.categories, function (category) {
                          return _c(
                            "option",
                            {
                              key: category.name,
                              domProps: { value: JSON.stringify(category) },
                            },
                            [
                              _vm._v(
                                _vm._s(category.name) +
                                  "\n                            "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center" }, [
                      _c("br"),
                      _vm._v(" "),
                      !_vm.savingSuccess
                        ? _c("span", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-daan-orange btn-with-icon",
                                on: {
                                  click: function ($event) {
                                    return _vm.attachCategories()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("cloud_download"),
                                ]),
                                _vm._v(" Koppelen\n              "),
                              ]
                            ),
                          ])
                        : _c("span", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-daan-orange btn-with-icon",
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("check_circle"),
                                ]),
                                _vm._v("Gelukt!\n              "),
                              ]
                            ),
                          ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "red-link",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel")
                            },
                          },
                        },
                        [_vm._v("Annuleren")]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }