var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "gallery-block" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row filter-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-xs-6 col-md-3 col-small-padding" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterData.search,
                        expression: "filterData.search",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Waar wil je op zoeken?",
                      type: "text",
                    },
                    domProps: { value: _vm.filterData.search },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.filterData,
                            "search",
                            $event.target.value
                          )
                        },
                        _vm.filter,
                      ],
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-xs-6 col-md-3 col-small-padding" },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterData.category,
                          expression: "filterData.category",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { label: "Categorie", name: "category" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filterData,
                              "category",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.filter,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Alle categorieen"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.categories, function (category) {
                        return _c(
                          "option",
                          { domProps: { value: category.id } },
                          [_vm._v(_vm._s(category.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-xs-6 col-md-3 col-small-padding" },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterData.type,
                          expression: "filterData.type",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { label: "Type", name: "type" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filterData,
                              "type",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.filter,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Alle types"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "images" } }, [
                        _vm._v("Afbeeldingen"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "msdoc" } }, [
                        _vm._v("Documenten"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "msxls" } }, [
                        _vm._v("Spreadsheets"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "msppt" } }, [
                        _vm._v("Presentaties"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "pdf" } }, [
                        _vm._v("PDF"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "youtube" } }, [
                        _vm._v("YouTube video's"),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-md-3 col-xs-12 text-center col-small-padding gallery-action-buttons",
                },
                [
                  _vm.Auth.getRole() === "admin" && _vm.checkedItems !== 0
                    ? _c(
                        "a",
                        {
                          staticClass: "col-1",
                          on: {
                            click: function ($event) {
                              return _vm.doAction("delete")
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            { staticClass: "material-icons orange-icon" },
                            [_vm._v("delete")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkedItems !== 0
                    ? _c(
                        "a",
                        {
                          staticClass: "col-1",
                          on: {
                            click: function ($event) {
                              return _vm.doAction("download")
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            { staticClass: "material-icons orange-icon" },
                            [_vm._v("vertical_align_bottom")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "pull-right" }, [
                    _vm.Auth.getRole() === "admin"
                      ? _c(
                          "a",
                          {
                            attrs: {
                              "data-placement": "top",
                              "data-toggle": "tooltip",
                              title: "Bestanden uploaden",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showModal = true
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "material-icons orange-icon" },
                              [_vm._v("vertical_align_top")]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        class: {
                          "orange-icon": _vm.allChecked,
                          "disabled-link": !_vm.allChecked,
                        },
                        attrs: {
                          "data-placement": "top",
                          "data-toggle": "tooltip",
                          title: "Selecteer alle items",
                        },
                        on: { click: _vm.checkAll },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("check_box"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.Auth.getRole() === "admin"
                      ? _c(
                          "a",
                          {
                            attrs: {
                              "data-placement": "top",
                              "data-toggle": "tooltip",
                              title: "Categorieën beheren",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showCategoryModal = true
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "material-icons orange-icon" },
                              [_vm._v("filter_none")]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.Auth.getRole() === "admin"
                      ? _c(
                          "a",
                          {
                            attrs: {
                              "data-placement": "top",
                              "data-toggle": "tooltip",
                              href: "/users",
                              title: "Gebruikers beheren",
                            },
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "material-icons orange-icon" },
                              [_vm._v("settings")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.mediaData, function (item) {
            return _c("div", { staticClass: "col-md-3 col-sm-6 col-xs-6" }, [
              _c(
                "div",
                {
                  staticClass: "gallery-block__item lazy",
                  style: _vm.backgroundStyle(item),
                  on: {
                    click: function ($event) {
                      return _vm.downloadItem(item.id)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "gallery-block-item__check block-button",
                      class: { checked: _vm.checkChecked(item.id) },
                      attrs: {
                        "data-placement": "top",
                        "data-toggle": "tooltip",
                        title: "Selecteer voor bulk-actie",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.checkItem(item.id)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("check"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.Auth.getRole() == "admin"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "gallery-block-item__delete block-button",
                          attrs: {
                            "data-placement": "top",
                            "data-toggle": "tooltip",
                            title: "Verwijder dit item",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteItem(item.id)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("delete"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "file-name" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "btn loadMoreButton btn-daan-gray",
                class: { disabled: _vm.currentItems === _vm.totalMediaFiles },
                attrs: {
                  "data-placement": "top",
                  "data-toggle": "tooltip",
                  title: "Laad volgende 12 items",
                },
                on: { click: _vm.loadMore },
              },
              [
                _vm._v(
                  "Meer laden (" +
                    _vm._s(_vm.currentItems) +
                    " van " +
                    _vm._s(_vm.totalMediaFiles) +
                    " geladen)\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.showModal
        ? _c("upload-modal", {
            on: {
              close: function ($event) {
                _vm.showModal = false
              },
              uploaded: function ($event) {
                return _vm.filter()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDeleteModal
        ? _c("delete-modal", {
            attrs: { items: _vm.deleteItems },
            on: {
              close: function ($event) {
                _vm.showDeleteModal = false
              },
              deleted: _vm.deleted,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showCategoryModal
        ? _c("category-modal", {
            on: {
              close: function ($event) {
                _vm.showCategoryModal = false
              },
              reloadcategories: _vm.getCategories,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showMediaModal
        ? _c("media-modal", {
            attrs: { mediaItemId: _vm.selectedMediaItemId },
            on: {
              close: function ($event) {
                _vm.showMediaModal = false
              },
              delete: _vm.deleteItem,
              download: _vm.downloadItem,
              mediaToCategory: _vm.mediaToCategory,
              update: _vm.filter,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showMediaToCategoryModal
        ? _c("media-to-category-modal", {
            attrs: { mediaItemId: _vm.selectedMediaItemId },
            on: {
              cancel: function ($event) {
                _vm.showMediaToCategoryModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }