<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div @click="$emit('close')" class="modal-wrapper">
                    <div @click.stop class="modal-container">
                        <div class="modal-header">
                            <slot name="header">
                                <h4 v-if="!id">Nieuwe gebruiker aanmaken</h4>
                                <h4 v-else>Gebruiker bewerken</h4>
                            </slot>
                            <div @click="$emit('close')" class="modal-header-close">
                                <i class="material-icons">close</i>
                            </div>
                        </div>

                        <div class="modal-body">
                            <form @submit="saveUser" action="/users" id="userForm" method="POST" name="userForm">
                                <input
                                    class="form-control"
                                    name="name"
                                    placeholder="Naam"
                                    type="text"
                                    v-model="userData.name"
                                />
                                <input
                                    class="form-control"
                                    name="email"
                                    placeholder="E-mailadres"
                                    type="text"
                                    v-model="userData.email"
                                />
                                <input
                                    class="form-control"
                                    name="password"
                                    placeholder="Wachtwoord"
                                    type="password"
                                    v-model="userData.password"
                                />
                                <input
                                    class="form-control"
                                    name="password_repeat"
                                    placeholder="Wachtwoord herhalen"
                                    type="password"
                                    v-model="userData.password_repeat"
                                />
                                <select
                                    class="form-control"
                                    name="role"
                                    placeholder="Gebruikersrol"
                                    v-model="userData.role"
                                >
                                    <option value>-- Kies gebruikersrol --</option>
                                    <option value="admin">Administrator</option>
                                    <option value="user">Gebruiker</option>
                                </select>
                                <div class="col-12">
                                <label>
                                    <input type="checkbox" class="" name="in_daan_cv" checked v-model="userData.in_daan_cv" value="true"> Ook toevoegen in MijnDaancv?
                                </label>
                                </div>
                                <hr/>
                                <div class="col-12 text-center">
                                    <a @click="saveUser($event)" class="btn btn-daan-orange btn-with-icon" v-if="!id">
                                        <i class="material-icons">save</i> Opslaan
                                    </a>
                                    <a @click="updateUser($event)" class="btn btn-daan-orange btn-with-icon" v-else>
                                        <i class="material-icons">save</i> Opslaan
                                    </a>
                                    <a @click="$emit('close')" class="red-link">Annuleren</a>
                                </div>
                            </form>
                            <div class="col-12" v-if="errors.length">
                                <hr/>
                                <b>Er is iets fout gegaan in de volgende velden:</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'app',
        props: ['id'],
        data() {
            return {
                userData: {
                    email: '',
                    password: '',
                    role: '',
                    in_daan_cv: true,
                    cms_role: ''
                },
                errors: {}
            };
        },
        mounted() {
            if (this.id !== false) {
                this.loadUser();
            } else {
                this.userData.name = '';
                this.userData.email = '';
                this.userData.password = '';
                this.userData.role = 'user';
                this.userData.in_daan_cv = true;
                this.userData.cms_role = '';
            }
        },
        methods: {
            loadUser() {
                axios
                    .get('/users/' + this.id)
                    .then(resp => {
                        if (resp.data) {
                            this.userData = resp.data;
                            if (this.userData.roles) {
                                this.userData.role = this.userData.roles[0].name;
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            saveUser(e) {
                e.preventDefault();
                if (this.checkForm(e)) {
                    axios.post('/users', this.userData).then(resp => {
                        if(resp.status == '200') {
                            alert('Succesvol aangemaakt!' + resp.data);
                            this.$emit('reload');
                            this.$emit('close');
                        }

                    }).catch(error => {
                        if (error.response.status === 422 && error.response.data.errors.email !== undefined) {
                            alert('E-mailadres is al in gebruik');
                        } else {
                            alert('Er ging iets mis bij het aanmaken, probeer het later nog eens')
                        }
                    })
                }
            },
            updateUser(e) {
                e.preventDefault();
                if (this.checkForm(e)) {
                    axios.post('/users/' + this.id, this.userData).then(resp => {
                        alert('Gebruiker opgeslagen!');
                        this.$emit('reload');
                        this.$emit('close');
                    }).catch(error => {
                        alert('Er ging iets mis bij het opslaan, probeer het later nog eens')
                    })
                }
            },
            checkForm: function (e) {
                this.errors = [];

                if (!this.userData.email) {
                    this.errors.push('E-mailadres is verplicht');
                }
                if (!this.userData.name) {
                    this.errors.push('Naam is verplicht');
                }
                if (this.userData.password !== this.userData.password_repeat) {
                    this.errors.push('Wachtwoord is verplicht en moet gelijk aan elkaar zijn');
                }
                if (!this.userData.role) {
                    this.errors.push('Een gebruikersrol is verplicht');
                }

                return this.errors.length === 0;
            }
        }
    };
</script>
<style scoped>
.form-control { margin:5px; }
</style>
