<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div @click="$emit('close')" class="modal-wrapper">
                    <div @click.stop class="modal-container">

                        <div class="modal-header">
                            <slot name="header">
                                <h4>Voeg bestanden toe</h4>
                            </slot>
                            <div @click="$emit('close')" class="modal-header-close"><i class="material-icons">close</i></div>
                        </div>

                        <div class="modal-body">
                            <slot name="body">
                                <vue-dropzone :options="dropzoneOptions"
                                              id="dropzone" ref="dropzoneUpload" v-on:vdropzone-error="uploadError" v-on:vdropzone-sending="onSend" v-on:vdropzone-success="uploadSuccess"
                                              v-on:vdropzone-success-multiple="uploadSuccess">
                                </vue-dropzone>
                                <div class="text-center or-separator">of</div>
                                <input class="form-control" name="youtube_url" placeholder="URL naar YouTube link" type="text" v-model="youtube_link">
                                <input class="form-control" name="youtube_title" placeholder="Titel voor YouTube video" type="text" v-if="youtube_link" v-model="youtube_title">
                            </slot>

                            <div class="row" v-if="selectedCategories.length">
                                <div class="col-12">
                                    <em>Gekozen categorieën</em>
                                </div>
                                <div class="col-12 category-list-item" v-for="(category, key) in selectedCategories">
                                    <div class="category-list-name">{{category.name}}</div>
                                    <div class="category-list-actions">
                                        <i @click="deleteCategory(category.id)" class="material-icons">delete</i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <slot name="footer">
                                <select @change="categoryChange($event)" class="form-control" name="category" placeholder="Kies een categorie" v-model="category_id">
                                    <option value="">Kies een categorie</option>
                                    <option v-bind:key="category.name" v-bind:value="JSON.stringify(category)" v-for="category in categories">
                                        {{ category.name }}
                                    </option>
                                </select>
                                <a @click="startUpload($event)" class="btn btn-daan-orange btn-with-icon" href="#">
                                    <i class="material-icons" v-if="!justSaved">save</i>
                                    <i class="material-icons just-saved animated bounceIn" v-else>check_circle</i> Toevoegen
                                </a>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    export default {
        name: "app",
        components: {
            vueDropzone: vue2Dropzone
        },
        data: function () {
            return {
                categories: '',
                selectedCategories: [],
                category_id: '',
                youtube_link: '',
                youtube_title: '',
                dropzoneOptions: {
                    autoProcessQueue: false,
                    url: '/media/upload',
                    maxNumberOfFiles: 100,
                    uploadMultiple: true,
                    parallelUploads: 100,
                    maxFilesize: 500,
                    thumbnailWidth: 100,
                    thumbnailHeight: 100,
                    timeout: 180000,
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                    },
                    dictDefaultMessage: "<h4>Klik of sleep hier om bestanden te uploaden</h4>"
                },
                justSaved: false
            };
        },
        mounted: function () {
            axios.get('/categories').then((resp) => {
                if (resp.data) {
                    this.categories = resp.data.data;
                }
            }).catch(error => {
                // handle error
                console.log(error);
            });
        },
        methods: {
            // on category change, add it to the stack
            categoryChange(event) {
                let item = JSON.parse(event.target.value);

                this.selectedCategories.push(item);
            },
            // remove category from the selection
            deleteCategory(id) {
                this.selectedCategories = _.reject(this.selectedCategories, (item) => {
                    return item.id === id
                })
            },
            // start uploading the files or push a YT link to the system
            startUpload(event) {
                event.preventDefault();
                if (this.youtube_link) {
                    let youtube_link = this.youtube_link;
                    let youtube_title = this.youtube_title;
                    axios.post('/media/upload', {youtube_link, youtube_title}).then((resp) => {
                        this.$emit('uploaded', true);
                        this.justSaved = true;
                        this.$emit('close');
                    })
                } else {
                    this.$refs.dropzoneUpload.processQueue();
                }
            },
            // before sending, add the category ids
            onSend(file, xhr, formData) {
                let checkedCategories = [];
                _.each(this.selectedCategories, (item) => {
                    checkedCategories.push(item.id);
                });
                formData.set('categories', checkedCategories);
            },
            // on upload success, emit a signal to the parent component
            uploadSuccess(file, response) {
                this.$emit('uploaded', true);
                this.justSaved = true;
                setTimeout(() => {
                    this.justSaved = false;
                    this.$refs.dropzoneUpload.removeAllFiles()
                }, 2000);
            },
            uploadError(file, response) {
                this.$emit('uploaded', true);
                this.justSaved = true;
                setTimeout(() => {
                    this.justSaved = false;
                    this.$refs.dropzoneUpload.removeAllFiles()
                }, 5);
            }
        }
    };
</script>
