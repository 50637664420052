var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "user-block" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row filter-row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-small-padding" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("table", { staticClass: "table user-table" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.users, function (user) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(user.name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(user.email))]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "ul",
                            _vm._l(user.roles, function (role) {
                              return _c("li", [
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(role.name) +
                                    "\n                                        "
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.editUser(user.id)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("edit"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.deleteUser(user.id)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("delete"),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-5" }),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex justify-content-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-daan-orange btn-with-icon",
                    attrs: {
                      "data-placement": "top",
                      "data-toggle": "tooltip",
                      title: "Nieuwe gebruiker",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.createUser()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("add_circle"),
                    ]),
                    _vm._v(" gebruiker"),
                  ]
                ),
                _c("br"),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(2),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.showUserModal
        ? _c("user-modal", {
            attrs: { id: _vm.selectedUserId },
            on: {
              close: function ($event) {
                _vm.showUserModal = false
              },
              reload: function ($event) {
                return _vm.loadUsers()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("h4", [_vm._v("Gebruikersbeheer")]),
      _vm._v(" "),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("td", [_vm._v("Gebruikersnaam")]),
      _vm._v(" "),
      _c("td", [_vm._v("E-mailadres (voor inloggen)")]),
      _vm._v(" "),
      _c("td", [_vm._v("Rol")]),
      _vm._v(" "),
      _c("td", [_vm._v("Acties")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }