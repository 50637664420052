var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c(
            "div",
            {
              staticClass: "modal-wrapper",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-container",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-header" },
                    [
                      _vm._t("header", function () {
                        return [
                          !_vm.id
                            ? _c("h4", [_vm._v("Nieuwe gebruiker aanmaken")])
                            : _c("h4", [_vm._v("Gebruiker bewerken")]),
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "modal-header-close",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("close"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "form",
                      {
                        attrs: {
                          action: "/users",
                          id: "userForm",
                          method: "POST",
                          name: "userForm",
                        },
                        on: { submit: _vm.saveUser },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userData.name,
                              expression: "userData.name",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "name",
                            placeholder: "Naam",
                            type: "text",
                          },
                          domProps: { value: _vm.userData.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.userData,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userData.email,
                              expression: "userData.email",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "email",
                            placeholder: "E-mailadres",
                            type: "text",
                          },
                          domProps: { value: _vm.userData.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.userData,
                                "email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userData.password,
                              expression: "userData.password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "password",
                            placeholder: "Wachtwoord",
                            type: "password",
                          },
                          domProps: { value: _vm.userData.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.userData,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userData.password_repeat,
                              expression: "userData.password_repeat",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            name: "password_repeat",
                            placeholder: "Wachtwoord herhalen",
                            type: "password",
                          },
                          domProps: { value: _vm.userData.password_repeat },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.userData,
                                "password_repeat",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userData.role,
                                expression: "userData.role",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "role",
                              placeholder: "Gebruikersrol",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.userData,
                                  "role",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("-- Kies gebruikersrol --"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "admin" } }, [
                              _vm._v("Administrator"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "user" } }, [
                              _vm._v("Gebruiker"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userData.in_daan_cv,
                                  expression: "userData.in_daan_cv",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "in_daan_cv",
                                checked: "",
                                value: "true",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.userData.in_daan_cv)
                                  ? _vm._i(_vm.userData.in_daan_cv, "true") > -1
                                  : _vm.userData.in_daan_cv,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.userData.in_daan_cv,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "true",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.userData,
                                          "in_daan_cv",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.userData,
                                          "in_daan_cv",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.userData, "in_daan_cv", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              " Ook toevoegen in MijnDaancv?\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 text-center" }, [
                          !_vm.id
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-daan-orange btn-with-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveUser($event)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("save"),
                                  ]),
                                  _vm._v(
                                    " Opslaan\n                                "
                                  ),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-daan-orange btn-with-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateUser($event)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("save"),
                                  ]),
                                  _vm._v(
                                    " Opslaan\n                                "
                                  ),
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "red-link",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("close")
                                },
                              },
                            },
                            [_vm._v("Annuleren")]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.length
                      ? _c("div", { staticClass: "col-12" }, [
                          _c("hr"),
                          _vm._v(" "),
                          _c("b", [
                            _vm._v(
                              "Er is iets fout gegaan in de volgende velden:"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function (error) {
                              return _c("li", [_vm._v(_vm._s(error))])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }