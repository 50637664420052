var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c(
            "div",
            {
              staticClass: "modal-wrapper",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-container",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-header" },
                    [
                      _vm._t("header", function () {
                        return [_c("h4", [_vm._v("Voeg bestanden toe")])]
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "modal-header-close",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("close"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _vm._t("body", function () {
                        return [
                          _c("vue-dropzone", {
                            ref: "dropzoneUpload",
                            attrs: {
                              options: _vm.dropzoneOptions,
                              id: "dropzone",
                            },
                            on: {
                              "vdropzone-error": _vm.uploadError,
                              "vdropzone-sending": _vm.onSend,
                              "vdropzone-success": _vm.uploadSuccess,
                              "vdropzone-success-multiple": _vm.uploadSuccess,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-center or-separator" },
                            [_vm._v("of")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.youtube_link,
                                expression: "youtube_link",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "youtube_url",
                              placeholder: "URL naar YouTube link",
                              type: "text",
                            },
                            domProps: { value: _vm.youtube_link },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.youtube_link = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.youtube_link
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.youtube_title,
                                    expression: "youtube_title",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "youtube_title",
                                  placeholder: "Titel voor YouTube video",
                                  type: "text",
                                },
                                domProps: { value: _vm.youtube_title },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.youtube_title = $event.target.value
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      }),
                      _vm._v(" "),
                      _vm.selectedCategories.length
                        ? _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("div", { staticClass: "col-12" }, [
                                _c("em", [_vm._v("Gekozen categorieën")]),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.selectedCategories,
                                function (category, key) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass: "col-12 category-list-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "category-list-name" },
                                        [_vm._v(_vm._s(category.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "category-list-actions",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "material-icons",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteCategory(
                                                    category.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("delete")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-footer" },
                    [
                      _vm._t("footer", function () {
                        return [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.category_id,
                                  expression: "category_id",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                name: "category",
                                placeholder: "Kies een categorie",
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.category_id = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.categoryChange($event)
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Kies een categorie"),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.categories, function (category) {
                                return _c(
                                  "option",
                                  {
                                    key: category.name,
                                    domProps: {
                                      value: JSON.stringify(category),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(category.name) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-daan-orange btn-with-icon",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.startUpload($event)
                                },
                              },
                            },
                            [
                              !_vm.justSaved
                                ? _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("save"),
                                  ])
                                : _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons just-saved animated bounceIn",
                                    },
                                    [_vm._v("check_circle")]
                                  ),
                              _vm._v(
                                " Toevoegen\n                            "
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }